import React, { useState } from "react";
import Subscribe from "../../components/common/Subscribe/Subscribe";

const Mailchimp = ({ from = "MODAL", closeModal, ...props }) => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const url =
    "https://theestablished.us5.list-manage.com/subscribe/post?u=8a608f298f4e1786ac7c33aac&amp;id=59eaf016b4";

  const subscribe = async (data) => {
    try {
      await fetch(
        `${url}&EMAIL=${encodeURIComponent(
          data?.EMAIL
        )}&FNAME=${encodeURIComponent(data?.FNAME)}&LNAME=${encodeURIComponent(
          data?.LNAME
        )}`,
        {
          method: "GET",
          mode: "no-cors",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      );
      setStatus("success");
    } catch (error) {
      setStatus("error");
      setMessage(error?.message || "Something went wrong");
    }
  };

  return (
    <div>
      <Subscribe
        status={status}
        message={message}
        onValidated={(formData) => {
          subscribe(formData);
        }}
        from={from}
        closeModal={closeModal}
        {...props}
      />
    </div>
  );
};

export default Mailchimp;
